import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import Swiper, { Pagination, EffectFade, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import 'swiper/css/autoplay'

Alpine.plugin(collapse);

window.Alpine = Alpine;

Swiper.use([Pagination, EffectFade, Autoplay]);
window.Swiper = Swiper;

Alpine.start();
